import cs from "classnames";
import React from "react";

import styles from "./index.module.less";

const FlexRow = (
  {
    children,
    size = 0,
    className,
    style,
    onClick,
    id,
    ...props
  }: {
    id?: string;
    children: React.ReactNode;
    size?: number | string;
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
  } & React.HTMLAttributes<HTMLDivElement>,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <div
      id={id}
      className={cs(styles["flex-row"], className)}
      style={{
        gap: size,
        ...style,
      }}
      ref={ref as React.Ref<HTMLDivElement>}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
};

export default React.forwardRef(FlexRow);
