import { useRequest } from "ahooks";

import { getRecommendLandingJobs } from "@/services/landing";

function useMarqueeJobs() {
  const { data } = useRequest(getRecommendLandingJobs, {});

  return data?.result?.jobList ?? [];
}

export default useMarqueeJobs;
