import { LANDING_ROUTE_KEY } from "@/enums";
import useIsMobile from "@/hooks/useIsMobile";
import { Divider, Flex, Typography } from "antd";
import cs from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

import { LANDING_MENU_ITEM_ENUM } from "@/constants";
import { BLOG_PATHNAME, TOOLS_ASSEMBLE } from "@/constants/pathname";

import styles from "./index.module.less";

const MEDIA_LINKS = [
  {
    href: "https://www.linkedin.com/company/jobright-ai/",
    src: "/newimages/public/linkedin.svg",
  },
  {
    href: "https://www.instagram.com/jobright.ai",
    src: "/newimages/public/instagram.svg",
  },
  {
    href: "https://www.tiktok.com/@jobright.ai",
    src: "/newimages/public/tiktok.svg",
  },
  {
    href: "https://twitter.com/jobrightai",
    src: "/newimages/public/twitter.svg",
  },
  {
    href: "https://discord.gg/tJeyxxsef9",
    src: "/newimages/public/discord.svg",
  },
  {
    href: "https://www.youtube.com/@jobrightai",
    src: "/newimages/public/youtube.svg",
  },
  {
    href: "https://www.facebook.com/jobrightai",
    src: "/newimages/public/facebook.svg",
  },
];

const WEBSITE_LINKS = [
  {
    title: "Features",
    keys: [
      LANDING_ROUTE_KEY.RESUME_AI,
      LANDING_ROUTE_KEY.JOB_MATCHING,
      LANDING_ROUTE_KEY.INSIDER_CONNECTIONS,
      LANDING_ROUTE_KEY.AI_COPILOT_ORION,
      LANDING_ROUTE_KEY.H1B_JOBS,
    ],
  },
  {
    title: "Blog",
    link: BLOG_PATHNAME,
    keys: [
      LANDING_ROUTE_KEY.LINKEDIN_101_GUIDE,
      LANDING_ROUTE_KEY.UNLIMATE_GUIDE_TO_H1B,
      LANDING_ROUTE_KEY.NEW_GRAD,
      LANDING_ROUTE_KEY.LAND_TOP_INTERNSHIP,
    ],
  },
  {
    title: "Tools",
    link: TOOLS_ASSEMBLE,
    keys: [
      LANDING_ROUTE_KEY.AI_JOB_ASSISTANT,
      LANDING_ROUTE_KEY.AI_COVER_LETTER_GENERATOR,
      LANDING_ROUTE_KEY.AI_RESUME_HELPER,
      LANDING_ROUTE_KEY.AI_JOB_TRACKER,
    ],
  },
  {
    title: "Information",
    keys: [
      LANDING_ROUTE_KEY.ABOUT_US,
      LANDING_ROUTE_KEY.PRIVACY_POLICY,
      LANDING_ROUTE_KEY.TERMS_OF_SERVICE,
    ],
  },
];
const GlobalFooter = ({ className }: { className?: string }) => {
  const mobile = useIsMobile();

  const [flexGap, setFlexGap] = useState<number | string>(0);
  const [flexAlign, setFlexAlign] = useState("start");

  useEffect(() => {
    if (mobile) {
      setFlexGap("1.5rem");
      setFlexAlign("center");
    }
  }, [mobile]);

  return (
    <footer
      className={cs(className, styles["landing-footer"], {
        [styles["mobile"]]: mobile,
      })}
    >
      <div className={styles["footer-content"]}>
        <Flex className={styles["logos"]} align={flexAlign} gap={flexGap}>
          <Image src="/newimages/logo.svg" width={160} height={40} alt="" />
          <div className={styles["media-links"]}>
            {MEDIA_LINKS.map(({ href, src }) => (
              <Link
                key={href}
                href={href}
                style={{
                  color: "#000",
                }}
                target="_black"
              >
                <Image src={src} width={24} height={24} alt="" />
              </Link>
            ))}
          </div>
        </Flex>

        <Flex vertical={mobile} className={styles["website-links"]}>
          <Flex vertical={mobile} gap={mobile ? "1.5rem" : 72}>
            {WEBSITE_LINKS.map(({ title, keys, link }, index) => [
              <Flex
                key={title}
                vertical
                gap={mobile ? "0.75rem" : 12}
                align={mobile ? "center" : "start"}
              >
                {link ? (
                  <Link href={link} target="_blank">
                    <Typography.Title level={3} className={styles["website-links-header"]}>
                      {title}
                    </Typography.Title>
                  </Link>
                ) : (
                  <Typography.Title level={3} className={styles["website-links-header"]}>
                    {title}
                  </Typography.Title>
                )}
                {keys.map(key => (
                  <Link key={key} href={LANDING_MENU_ITEM_ENUM[key].route} target="_blank">
                    {LANDING_MENU_ITEM_ENUM[key].text}
                  </Link>
                ))}
              </Flex>,
              mobile && index !== WEBSITE_LINKS.length - 1 && (
                <Divider key={`divider-${index}`} className={styles["divider"]} />
              ),
            ])}
          </Flex>
        </Flex>
      </div>
    </footer>
  );
};

export default GlobalFooter;
