import { useRequest } from "ahooks";
import { Button, ButtonProps, Flex, Form, Input, Modal, Typography, message } from "antd";
import Image from "next/image";
import React, { useEffect, useState } from "react";

import { postInviteEnterprise } from "@/services/enterprise";
import { components } from "@/services/schema";

import { isBrowser, trackEvent } from "@/utils";

import styles from "./index.module.less";

const prefixCls = "enterprise-invite-popup";

type EnterpriseInvitePopupProps = {
  buttonProps: ButtonProps;
  buttonText?: string;
  onOpenAmplitudeEvent?: string;
  onOpenAmplitudeScene?: string;
};

const CALENDLY_URL = "https://calendly.com/eric-seekgs/meet-with-jobright";

const EnterpriseInvitePopup: React.FC<EnterpriseInvitePopupProps> = ({
  buttonProps,
  buttonText,
  onOpenAmplitudeEvent,
  onOpenAmplitudeScene,
}) => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const { run } = useRequest(postInviteEnterprise, {
    manual: true,
    onSuccess: () => {
      messageApi.info({
        icon: <></>,
        content: "Invite sent successfully",
      });

      if (isBrowser) {
        window.open(CALENDLY_URL, "_blank");
      }

      onClose();
    },
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      trackEvent("employer_landing_survey_exposure");
    }
  }, [open]);

  const onOpen = () => {
    if (onOpenAmplitudeEvent) {
      trackEvent(onOpenAmplitudeEvent, {
        scene: onOpenAmplitudeScene,
      });
    }

    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: components["schemas"]["BusinessContactRequest"]) => {
    trackEvent("employer_landing_survey_submit");

    run(values);
  };

  return (
    <>
      {messageContextHolder}
      <Modal
        centered
        open={open}
        maskClosable={false}
        onCancel={onClose}
        width={480}
        className={styles[prefixCls + "-modal"]}
        rootClassName={styles[prefixCls + "-modal-root"]}
        title={
          <Flex
            align="center"
            justify="space-between"
            className={styles[prefixCls + "-modal-title"]}
          >
            <Typography.Title level={4}>Let’s Connect!</Typography.Title>
            <Image
              style={{
                cursor: "pointer",
              }}
              width={16}
              height={16}
              alt="close-icon"
              src={"/newimages/public/close.svg"}
              onClick={onClose}
            />
          </Flex>
        }
        footer={null}
        closeIcon={null}
      >
        <Flex vertical gap={24} className={styles[prefixCls + "-modal-content"]}>
          <Typography.Paragraph className={styles[prefixCls + "-description"]}>
            Please fill out your details below, and we’ll guide you to schedule a call with our
            team.
          </Typography.Paragraph>
          <Form<components["schemas"]["BusinessContactRequest"]>
            className={styles[prefixCls + "-form"]}
            layout="vertical"
            variant="borderless"
            form={form}
            onFinish={onSubmit}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number",
                },
              ]}
            >
              <Input placeholder="Enter Phone Number" />
            </Form.Item>

            <Form.Item
              label="Job Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter your job title",
                },
              ]}
            >
              <Input placeholder="Enter current job title" />
            </Form.Item>

            <Form.Item
              label="Company"
              name="company"
              rules={[
                {
                  required: true,
                  message: "Please enter your company",
                },
              ]}
            >
              <Input placeholder="Enter current company" />
            </Form.Item>
            <Form.Item
              label="What kinds of roles are you currently hiring for?"
              name="hiringRoles"
              rules={[
                {
                  required: true,
                  message: "Please specify the roles your company is hiring for.",
                },
              ]}
            >
              <Input placeholder="Enter roles you’re hiring for" />
            </Form.Item>
          </Form>

          <Button
            className={styles[prefixCls + "-schedule-call-button"]}
            type="text"
            onClick={form.submit}
          >
            Schedule a Call
          </Button>
        </Flex>
      </Modal>
      <Button className={styles[prefixCls + "-button"]} {...buttonProps} onClick={onOpen}>
        {buttonProps?.children ?? buttonProps?.children ?? buttonText ?? "Get Started for Free"}
      </Button>
    </>
  );
};

export default EnterpriseInvitePopup;
