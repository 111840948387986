import Image from "next/image";

type JobrightLogoProps = {
  theme?: "light" | "dark";
  className?: string;
  width?: number;
  height?: number;
};

const JobrightLogo: React.FC<JobrightLogoProps> = props => {
  return (
    <Image
      src={"/newimages/logo.svg"}
      alt="logo"
      width={props?.width ?? 192}
      height={props?.height ?? 48}
      className={props?.className}
      priority
    />
  );
};

export default JobrightLogo;
