import { API_HOST } from "@/global";
import axios from "axios";

import { components } from "./schema";

axios.defaults.withCredentials = true;

export const postInviteEnterprise = (data: components["schemas"]["BusinessContactRequest"]) => {
  return axios.post<components["schemas"]["BaseResponseBoolean"]>(
    `${API_HOST}/swan/business/contact-us`,
    data
  );
};

export const postEnterpriseCandidateFeedback = (
  params: components["schemas"]["BusinessCandidateFeedbackRequest"]
) => {
  return axios.post<components["schemas"]["BaseResponseBoolean"]>(
    `${API_HOST}/swan/business/candidate/feedback`,
    params
  );
};
