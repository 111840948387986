import { API_HOST } from "@/global";
import axios, { AxiosRequestConfig } from "axios";

import { parseResponse } from "@/utils/services";

import { components } from "./schema";
import { JobInfoType } from "./types";

export const getRecommendLandingJobs = async (): Promise<{
  result?: { jobList?: JobInfoType[] };
}> => {
  return axios.get(`${API_HOST}/swan/recommend/landing/jobs`).then(res => {
    return res.data;
  });
};

export const postOneLineParse = async (
  params: components["schemas"]["OneLineRequest"],
  config?: AxiosRequestConfig
): Promise<components["schemas"]["BaseResponseOneLineSummaryResult"]> => {
  return axios.post(`${API_HOST}/swan/landing/oneline/parse`, params, config).then(res => {
    return res.data;
  });
};

export const fetchJobDiagnostic = async (
  params: components["schemas"]["JobDiagnosticRequest"],
  config?: AxiosRequestConfig
) => {
  return axios.post(`${API_HOST}/swan/landing/job/diagnostic`, params, config).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as components["schemas"]["JobDiagnosticResult"];
    }
  });
};

export const postSeekerTypeSelection = async (
  seekerType: components["schemas"]["SeekerTypeRequest"]
) => {
  return axios
    .post<components["schemas"]["BaseResponse"]>(`${API_HOST}/swan/landing/seeker-type`, seekerType)
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const postCareerGoalSelection = async (
  careerGoal: components["schemas"]["CareerGoalRequest"]
) => {
  return axios
    .post<components["schemas"]["BaseResponse"]>(`${API_HOST}/swan/landing/career-goal`, careerGoal)
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const postBasicPreferenceInfo = async (
  params: components["schemas"]["BasicInfoRequest"]
) => {
  return axios
    .post<
      components["schemas"]["BaseResponseCheckJobTitleResult"]
    >(`${API_HOST}/swan/landing/basic/pref`, params)
    .then(res => {
      return parseResponse<components["schemas"]["BaseResponseCheckJobTitleResult"]["result"]>(res);
    });
};

export const postBasicJobTitles = async (
  params: components["schemas"]["BasicInfoTitleRequest"]
) => {
  return axios
    .post<components["schemas"]["BaseResponse"]>(`${API_HOST}/swan/landing/basic/titles`, params)
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const postAdvancedPreferenceInfo = async (
  params: components["schemas"]["AdvancedPreferenceRequest"]
) => {
  return axios
    .post<
      components["schemas"]["BaseResponseBoolean"]
    >(`${API_HOST}/swan/landing/advanced/pref`, params)
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const fetchOnboardingV3PreferenceInfo = async () => {
  return axios
    .get<
      components["schemas"]["BaseResponseOnboardingV3FilterResult"]
    >(`${API_HOST}/swan/landing/pref/get`)
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const postActivateLinkedinURL = async (
  params: components["schemas"]["ResumeByLinkedinRequest"]
) => {
  return axios.post<components["schemas"]["BaseResponseBoolean"]>(
    `${API_HOST}/swan/resume/linkedin`,
    params
  );
};
