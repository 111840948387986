import cs from "classnames";
import Image from "next/image";
import React from "react";

import styles from "./index.module.less";

type WrapperedIconProps = {
  className?: string;
  src: string;
  inner: number;
  outer: number | string;
  radius: number;
  alt: string;
  style?: React.CSSProperties;
};

const WrapperedIcon: React.FC<WrapperedIconProps> = ({
  className,
  src,
  inner = 30,
  outer = 60,
  radius = 30,
  alt,
  style,
}) => {
  return (
    <div
      className={cs({
        [styles["icon-wrapper"]]: true,
        [className as string]: !!className,
      })}
      style={{
        width: outer,
        height: outer,
        borderRadius: radius,
        ...(style ?? {}),
      }}
    >
      <Image src={src} width={inner} height={inner} alt={alt} />
    </div>
  );
};

export default WrapperedIcon;
