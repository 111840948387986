import Image, { ImageProps } from "next/image";
import React from "react";

const AutoSizeSquareImage = (
  props: ImageProps & {
    errorSrc?: string;
    style?: React.CSSProperties;
  }
) => {
  const { errorSrc, style, ...imageProps } = props;
  const [src, setImgSrc] = React.useState(props.src);

  return (
    <div
      style={{
        minWidth: props.width || props.height || 72,
        minHeight: props.width || props.height || 72,
        width: props.width || props.height || 72,
        height: props.width || props.height || 72,
        display: "block",
        position: "relative",
        ...style,
      }}
    >
      <Image
        {...imageProps}
        src={src}
        alt={props.alt || "image"}
        height={props.height}
        style={{
          ...props.style,
        }}
        onError={e => {
          if (props.errorSrc) {
            setImgSrc(props.errorSrc);
          }
        }}
      />
    </div>
  );
};

export default AutoSizeSquareImage;
