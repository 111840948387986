import useIsMobile from "@/hooks/useIsMobile";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

import FlexRow from "../FlexRow";

const FeaturedByOpenai: React.FC<{ className?: string }> = ({ className }) => {
  const [gap, setGap] = useState(48);
  const [imageSize, setImageSize] = useState({
    width: 160,
    height: 48,
  });

  // 使用 useEffect 确保只在客户端执行
  const isMobile = useIsMobile();
  useEffect(() => {
    if (isMobile) {
      setGap(16);
      setImageSize({
        width: 80,
        height: 24,
      });
    }
  }, [isMobile]);

  return (
    <FlexRow size={gap} className={classNames(className)}>
      <Link href="https://www.producthunt.com/products/jobright-ai-2#jobright-ai-2" target="_blank">
        <Image src="/newimages/landing/phmonth.svg" alt="second-page-image" {...imageSize} />
      </Link>
      <Image src="/newimages/landing/openaitop.svg" alt="second-page-image" {...imageSize} />
    </FlexRow>
  );
};

export default FeaturedByOpenai;
